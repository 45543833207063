<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}
      </h3>
    </div>
    <div class="box-body">
      <vue-tabs class="panel">
        <v-tab :key="index"
               :title="role.name"
               class="nav-tab-content no-padding"
               v-for="(role, index) in roles"
               v-if="role.id !== 1">
          <div  v-for="(routes, component) in role['routes']">
            <div class="panel panel-default mb-0" style="background: rgba(158, 158, 162, 0.25)">
              <div class="panel-heading">
                <h5 class="font-medium">{{ component }}</h5>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover no-border mb-0">
                <tr v-for="access in routes">
                  <td>
                    <div style="font-size: 15px;">
                      {{ access.route.name }}
                    </div>
                  </td>
                  <td>
                    <label>
                      <input :checked="access.has_access" class="pointer"
                             style="transform: scale(1.4);" type="checkbox"
                             v-on:input="editAccess(access.id, $event)">
                    </label>
                  </td>
                </tr>
                <tr v-if="role['routes'].length === 0">
                  <td class="bg-white" colspan="10">
                    <p class="mt-5 mb-5 text-muted text-center">
                      Нет элементов для отображения
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import { VTab, VueTabs } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import RolesService from "@/services/RolesService";

export default {
  name: "accesses",

  components: {
    VueTabs,
    VTab,
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Права доступа",

    roles: [],
  }),

  async created() {
    try {
      this.roles = await RolesService.getItems();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async editAccess(accessId, event) {
      await API.apiClient.put("/employees/accesses/edit/" + accessId, {
        has_access: event.target.checked,
      }).then(() => {
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style scoped>

</style>
